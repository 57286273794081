/* Sidebar */
.sidebar {
    position: fixed;
    top: 90px; /* Below the header */
    left: 0;
    flex-direction: column;
    width: 280px;
    height: calc(100vh - 90px); /* Full height minus header height */
    background: #262636;
    border-right: 1px solid #EAECF0;
    padding-top: 32px;
}

.nav-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px;
    gap: 4px;
    width: 280px;
    height: auto;
}

a {
    color: inherit; /* Inherit color from parent element */
    text-decoration: none; /* Remove underline */
}

a:visited {
    color: white; /* Remove purple color after link is clicked */
}
