
.app-wrapper {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*height: 100vh;*/
    overflow: hidden; /* Prevents overall page scroll */
}

/* Main content area that includes the sidebar and page content */

.main-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    height: calc(100% - 90px);


}

/* Page content next to sidebar */
.page-content {
    flex: 1;
    margin-left: 280px; /* Space for sidebar */
    overflow-y: auto;
    overflow-x: hidden; /* Prevents horizontal scroll */
    /* Enables scrolling within the main content */
    margin-top: 90px;
}
