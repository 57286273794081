/* auth.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    height: 100%;
    background: #FFFFFF;
}

.auth-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.auth-container {
    display: flex;
    width: 100%;
    height: 100vh;
    background: #191924;
;
    overflow: hidden;
}

.auth-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    min-width: 480px;
    height: 100vh;
    justify-content: center;
    position: relative;
}

.image-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensures cropping */
    border-radius: 48px 0 0 48px;
    position: relative;
    background: #191924; /* Fallback for empty space */
}

.image-section img {
    width: auto;
    height: auto;
    max-width: 720px; /* Original width */
    max-height: 960px; /* Original height */
    object-fit: cover; /* Ensures cropping without distortion */
}

.logo-container {
    text-align: center;
}

.logo-circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    margin-left: 30px;
    margin-top: 30px;
}

.auth-content {
    height: 100%;
    width: 100%;
}

.main-container {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content inside main-container */
    justify-content: center; /* Ensure vertical alignment if necessary */
    padding: 20px;
    margin: 20% auto; /* Ensure the container itself is centered */
    text-align: center; /* Center-align text */
}


.welcome-text {
    font-size: 30px;
    font-weight: 600;
    color: #FFF;
    margin-bottom: 12px;
}

.subtext {
    font-size: 16px;
    color: #D0D5DD;
    margin-bottom: 24px;
    white-space: pre-wrap;
}

.auth-toggle-text {
    margin-top: 20px;
    font-size: 14px;
    color: #D0D5DD;
}

.auth-toggle-text a {
    color: #6941C6;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
}

.auth-toggle-text a:hover {
    text-decoration: underline;
}

.loader {
    margin: 20px 0;
}

.google-login, .linkedin-login {
    height: 44px;
    margin-bottom: 12px;
}

.google-login .social-icon,
.linkedin-login .social-icon {
    width: 24px;
    height: 24px;
}



.google-login {
    width: 100%;
    max-width: 360px;
    height: 44px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden; /* Ensures content doesn't overflow */
    position: relative; /* For additional styling control */
}



.google-login .google-button-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #344054;
    padding: 10px;
    box-sizing: border-box;
}


.footer {
    width: 100%;
    text-align: center;
    padding: 16px 0;
    position: absolute;
    bottom: 20px;
}

