/* Pagination container styles */
.pagination-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 12px 0px 16px;
    gap: 12px ;
    width: 100%;
    border-top: 1px solid #EAECF0;
}

/* Previous and Next button styles */
.pagination-button {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    gap: 4px;
    width: 111px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    cursor: pointer;
}

.pagination-button:hover {
    background: #FAE0F3;
}


.pagination-button:disabled {
    background: #FFFFFF;
    color: #98A2B3;
    cursor: not-allowed;
}


/* Arrow icons for Previous and Next buttons */
.arrow-left, .arrow-right {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: #98A2B3;
}

/* Page numbers container */
.pagination-numbers {
    display: flex;
    align-items: center;
    gap: 4px;
}

/* Individual page number button styles */
.pagination-number {
    width: 40px;
    height: 40px;
    background: #FDF2FA;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    cursor: pointer;
    border: none;
}

.pagination-number.active {
    background: #FFFFFF;
    color: #182230;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.pagination-number:hover {
    background: #FAE0F3;
    color: #182230;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);}

/*    !* Dots style for ellipsis *!*/
.pagination-number:disabled {
    background: none;
    color: #98A2B3;
    cursor: default;
}
