.nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;
    width: 248px;
    height: 40px;
    background: #262636; /* Default */
    border-radius: 6px;
    transition: background-color 0.3s;
    box-sizing: border-box;
    color : #ffffff;
}



.icon {
    width: 24px;
    height: 24px;
    /*border: 2px solid #667085;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 24px;
    font-size: 14px;
}

.nav-item:hover {
    background: rgba(38, 3, 30, 0.24);
    color: #EE46BC;

}

.nav-item.active {
    background: #EE46BC3D;
    color: #EE46BC;

}


