/* Header */
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    height: 90px;
    background: #323248;
    width: 100%;
    z-index: 1000;


}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    height: 50%;
    justify-content: space-between;
    box-sizing: border-box;
}

/* Logo */
.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 104px;
}


/* Navigation */
.navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    flex-grow: 1;
}

.nav-item1 {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    padding: 6px 16px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
}

.nav-item1:hover {
    color: #EE4BCC;
}

.nav-item1:visited {
    color: white; /* Ensures visited links stay the same */
}

.nav-item1.active {
    color: #EE4BCC;
;
}





.username {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: #FFFFFF;
}

/* Actions */
.nav-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}

.login-button {
    padding: 10px 16px;
    background: rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(255, 255, 255, 0.24);
    border-radius: 8px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 16px;
}

.user-avatar {
    width: 40px;
    height: 40px;
    /*background: url('Olivia_Rhye.png');*/
    border-radius: 50%;
    border: 0.75px solid rgba(0, 0, 0, 0.08);
}

.avatar-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
